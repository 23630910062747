#timeleft {
  position: relative;
}

.timeleft-waking-time-left-of-unit {
  background-color: rgb(56, 77, 186);
  top: 0px;
  display: inline-block;
  height: calc(100% + 9px);
  position: absolute;
  width: 2px;
}

.timeleft-time-left-of-unit {
  background-color: rgb(182, 73, 54);
  top: 0px;
  display: inline-block;
  height: calc(100% + 34px);
  position: absolute;
  width: 2px;
}

.timeleft-time-left-formatted {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  position: absolute;
  right: -43px;
  bottom: -16px;
  text-align: center;
}

.timeleft-time-left-formatted .time {
  border-radius: 5px 5px 5px 5px;
  color: white;
  display: inline-block;
  padding: 2px 4px;
  width: 56px;
}

.timeleft-waking-time-left-of-unit .timeleft-time-left-formatted .time {
  background-color: rgb(56, 77, 186);
}

.timeleft-time-left-of-unit .timeleft-time-left-formatted .time {
  background-color: rgb(182, 73, 54);
}