#timebar {
  height: 80px;
  position: relative;
  background-color: rgb(247, 233, 199);
  border-radius: 2px;
  overflow: hidden;

  // -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  // -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  // box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
}

.timebar-time-left-of-planned {
  background-color: @primary-color;
  color: white;
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.timebar-info {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: row;
  gap: 10px;
}

.time-left-in-week {
  color: @primary-color;
  font-size: 14px;
  font-weight: bold;
}

@primary-color: rgb(79, 135, 121);