.timer {
  height: 80px;
  position: relative;
  background-color: rgb(247, 233, 199);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border-radius: 2px;
  // margin-bottom: 10px;
  
  // filter: blur(10px);

  // -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  // -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  // box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
}


// .timer:before {
//   content: “ ”;
//   background: inherit; 
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0; 
//   bottom: 0;
//   box-shadow: inset 0 0 0 3000px rgba(255,255,255,0.3);
//   filter: blur(10px);
//  }

.timer-time-left-of-planned {
  background-color: @primary-color;
  color: white;
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.timer-overage-of-planned {
  background-color: rgb(209, 189, 142);
  color: white;
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

// .timer-time-planned-marker {
//   border-right: 2px solid rgb(242, 206, 81);
//   display: inline-block;
//   height: 80px;
//   position: absolute;
// }

.timer-time-left-formatted {
  float: left;
  margin: 10px 15px;
}

.timer-info {
  height: 100%;
  // left: 0;
  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: row;
  gap: 10px;
}

.timer-name {
  // position: absolute;
  // display: inline-block;
  color: rgb(92, 92, 92);
  font-size: 18px;
  // left: 15px;
  // top: 7px;
  font-weight: bold;
  margin-bottom: 3px;
  margin-left: 8px;
}

.timer-elapsed-and-duration {
  // position: absolute;
  // display: inline-block;
  color: @primary-color;
  // bottom: 27px;
  // left: 15px;
  font-size: 14px;
  font-weight: bold;
}

.timer-elapsed-of-current-session {
  // position: absolute;
  // display: inline-block;
  color: white;
  bottom: 8px;
  left: 15px;
  font-size: 14px;
  font-weight: bold;
}

.blinking {
  animation: blinkingText 1s infinite;
}

@keyframes blinkingText {
  0%, 49% {
    opacity: 1;
  }
  50%, 99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.play, .stop {
  position: absolute;
  right: 20px;
  top: 15px;
  bottom: 15px;
  cursor: pointer;
  font-size: 50px;
}
@primary-color: rgb(79, 135, 121);