body {
  margin: 0;
  background-color: rgb(225, 225, 225);
}

#root {
  height: 100%;
}

.layout {
  background-color: transparent;
  height: 100%;
}

.header {
  background-color: transparent;
}

.countdowns-container {
  text-align: center;
  margin: 80px 0;
}

@media (max-width: 767.98px) {
  .header {
      padding-left: 15px;
      padding-right: 15px;
  }

  .avatarName {
      display: none;
  }

  .logo-container {
      text-align: center;
  }

  .authorization-button-container {
      display: none;
  }

  .countdowns-container {
      margin: 40px 0;
  }

  .landing-footer {
      text-align: center;
  }
}

.footer {
  text-align: center;
  background-color: transparent;
}

.landing {
  background-color: rgb(255, 255, 255);
}

.landing-header {
  height: 68.05px;
}

.feature-illustration {
  font-size: 3em;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 16px;
}

.landing-footer {
  background-color: #001529;
}
@primary-color: rgb(79, 135, 121);